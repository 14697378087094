import dayjs from "dayjs"
import { FileStorageHelper } from "./file_storage_helper"
import { SchoolDate } from "./value_object"
// import { DO_NOT_USE_OR_YOU_WILL_BE_FIRED_EXPERIMENTAL_CREATE_ROOT_CONTAINERS } from "react-dom/client"


export class Util {
  static convertStatusToText(status: string, isSync: boolean) {
    if (status === 'progressing') {
      return '申請中'
    } else if (status === 'parent_sign') {
      return '待家長簽核'
    } else if (status === 'rejected') {
      return '已退回'
    } else if (status === 'finished' && !isSync) {
      return '同步失敗'
    } else if (status === 'finished') {
      return '已完成'
    } else if (status === 'canceled') {
      return '撤單'
    } else {
      return '無'
    }
  }

  static convertStatusToTextEn(status: string, isSync: boolean) {
    if (status === 'progressing') {
      return 'Pending'
    } else if (status === 'parent_sign') {
      return 'Awaiting Parent approval'
    } else if (status === 'rejected') {
      return 'Declined'
    } else if (status === 'finished' && !isSync) {
      return 'Sync failed'
    } else if (status === 'finished') {
      return 'Completed'
    } else if (status === 'canceled') {
      return 'Retrieved'
    } else {
      return 'Null'
    }
  }

  static convertRoleToText(roleType: string) {
    if (roleType === 'student') {
      return '學生';
    } else if (roleType === 'parent') {
      return '家長';
    } else if (roleType === 'teacher') {
      return '教師';
    } if (roleType === 'admin') {
      return '管理者';
    } else {
      return '無';
    }

  }

  static convertRoleToTextEn(roleName: string) {
    let stageNameEn;
    switch (roleName) {
      case "班導師":
        stageNameEn = "Homeroom Teacher";
        break;
      default:
        stageNameEn = roleName;
    }
    return stageNameEn
  }

  static convertApproveToText(approveType: string) {
    if (approveType === 'approved') {
      return '同意';
    } else if (approveType === 'rejected') {
      return '退回';
    } else {
      return '(待核准)';
    }

  }
  static convertApproveToTextEn(approveType: string) {
    if (approveType === 'approved') {
      return 'Approved';
    } else if (approveType === 'rejected') {
      return 'Decline';
    } else {
      return '( To be Approved )';
    }

  }

  /** 計算請假單的進度文字 */
  static calculateProgressText = (leaveApplicationRecord: any) => {

    const oStatus: string = (leaveApplicationRecord)? leaveApplicationRecord.status:'';
    const isSync: boolean = (leaveApplicationRecord)? leaveApplicationRecord.is_sync_success:null;
    const result: {
      progressText: string;
      progressTextEn: string;
      progressTextCSS: string;
      statusText: string;
      statusTextEn: string;
      statusTextCSS: string;
      status: string;
    } = {
      progressText: "",
      progressTextEn: "",
      progressTextCSS: "",
      statusText: "",
      statusTextEn: "",
      statusTextCSS: "",
      status: oStatus
    };

    try {
      
      if (oStatus === "parent_sign" || oStatus === "progressing") {
        result.progressText = "申請中";
        result.progressTextEn = "Pending";
        result.progressTextCSS = "bg-ing";
        if (oStatus === "parent_sign") {
          result.statusText = "待家長簽名";
          result.statusTextEn = "Awaiting Parent Signature";
          result.statusTextCSS = "text-red-500";
        } else {
          result.statusText = `待${leaveApplicationRecord.current_stage_name}簽核`;
          const roleName = leaveApplicationRecord.current_stage_name;
          let stageNameEn;
          stageNameEn = Util.convertRoleToTextEn(roleName);
          result.statusTextEn = `Awaiting ${stageNameEn} approval`;
          
          result.statusTextCSS = "";
        }
      } else if (oStatus === "rejected" || oStatus === "canceled") {
        result.progressTextCSS = "bg-return";
        result.progressText = "退回";
        result.progressTextEn = "Decline";
        if (oStatus === "rejected") {
          const targetStage = leaveApplicationRecord.approveHistory.find(
            (stage: any) => stage.status === oStatus
          );
          result.statusText = targetStage
            ? `${targetStage.stage_name}未核准`
            : "未核准";
          let stageNameEn;
          if (targetStage) {
            const roleName = targetStage.stage_name;
            stageNameEn = Util.convertRoleToTextEn(roleName);
          }
          result.statusTextEn = targetStage
            ? `Declined by ${stageNameEn}`
            : "Declined";
        } else {
          result.statusText = "已抽單";
          result.statusTextEn = "Retrieved";
        }
      } else if (oStatus === "finished" && !isSync) {
        result.progressText = "同步失敗";
        result.progressTextEn = "Sync failed";
        result.progressTextCSS = "bg-failed";
      } else if (oStatus === "finished") {
        result.progressText = "已完成";
        result.progressTextEn = "Completed";
        result.progressTextCSS = "bg-ed";
      } else {
        result.progressText = "無";
        result.progressTextCSS = "";
      }

    } catch (ex) {
      console.log({ ex });
    }

    return result;
  };

  /** 上傳檔案 */
  static uploadFile = async (file: File | undefined) => {
    if (!file) return;
    try {
      const attInfo = await FileStorageHelper.uploadFile(file);
      console.log(attInfo);
      return attInfo;
    } catch (ex) {
      throw ex ;
    }
  };

  /** 取得檔案的下載網址 */
  static getFileUrl = (fileId: string) => {
    return `https://storage.1campus.net/file/${fileId}`
  }

  /** 計算指定的多個日期是否是連續日期 */
  static isContinousDates = ( dates: string[], schoolDates: SchoolDate[]) => {
    // console.log({ dates, schoolDates, test: 'test' });

    let result = true ;
    if (dates.length < 2) { return result ;}

    // console.log({ msg: 'dates has 2 more days.'})

    if (schoolDates.length > 0) {
      // 如果學校有設定上課日，則以上課日來判斷
      let targetIndex = -1 ;
      dates.forEach( (d, index) => {
        // console.log( { d, targetIndex });
        // 如果是第一日比對，則找出在上課日中對應的 index 
        if (targetIndex === -1) {
          const targetDate = schoolDates.find( sd => dayjs(sd.d).format('YYYY/MM/DD') === dayjs(d).format('YYYY/MM/DD'));
          // console.log( { targetDate });
          if (targetDate) {
            targetIndex = schoolDates.indexOf(targetDate);  // 找出第一個日期，在 schoolDates 中的 index.
          } else {
            return false ;
          }
          // console.log( { action: 'start', targetIndex, targetDate})
        } else { // 如果不是第一日，則把 targetIndex + 1 找出下一個 school Date 比對日期
          const targetDate = schoolDates[targetIndex + 1];
          // console.log({ targetIndex, targetDate });

          if (targetDate) {
            result = dayjs(targetDate.d).format('YYYY/MM/DD') === dayjs(d).format('YYYY/MM/DD') ; // 下一個日期是否和下一個 schoolDate 相同
            // console.log( {targetDate, d, result} )
            if (!result) { return false ;}  // 如果下一日不相同，就不算連續日
          } else {
            return false ; // 找不到，也是不連續日期
          }
          targetIndex += 1;
        }
      })
    } else { // 如果學校沒有設定上課日，則以跳過六日做判斷。
      // 想法： 針對遞增排序過的日期，
      // 1. 找出第一筆日期是星期幾。
      // 2. 找出第二筆日期與第一筆日期差多少天？如果只差一天，就是連續日期。若不是差一天，則判斷星期是否跳過 六日？
      let targetDate = dayjs(dates[0]);
      let targetWeekday = targetDate.day();
      for( let i=1; i< dates.length; i++) {
        const newDate = dayjs(dates[i]);
        const dateDiff = targetDate.diff(newDate, 'day');
        if (dateDiff > 1) {
          if (targetWeekday === 5 && newDate.day() === 1) {
            result = true ;
          } else {
            result = false ;
            break ;
          }
        }
        targetDate = newDate ;
      }
    }
    return result ;
  }
  /** 重新排序核准歷程 */
  static resortApproveHistory = (leaveapp: any[]) => {
    const result = leaveapp.map((lapp: any) => {
      const approveHistory = lapp.approveHistory;
      const newApproveHistory = approveHistory.sort((a: any, b: any) => {
        return a.stage_no - b.stage_no;
      });
      lapp.approveHistory = newApproveHistory;
      return lapp;
    });
    return result;
  }
  // 重新排序請假日期及核准歷程
  static reSortLeaveApp = (leaveapp: any[]) => {
    const result = leaveapp.map((lapp: any) => {
      const approveHistory = lapp.approveHistory;
      const newApproveHistory = approveHistory.sort((a: any, b: any) => {
        return a.stage_no - b.stage_no;
      });
      lapp.approveHistory = newApproveHistory;

      const leaveDateAndPeriod=lapp.leaveDateAndPeriod;
      const newLeaveDateAndPeriod=leaveDateAndPeriod.sort((a: any, b: any) => {
        return new Date(a.d).getTime() - new Date(b.d).getTime();
      });
      lapp.leaveDateAndPeriod=newLeaveDateAndPeriod
      return lapp;
    });
    return result;
  }

    // 重新排序請假日期及核准歷程(加上關懷班級)
    static reSortLeaveAppWithAC = (leaveapp: any[]) => {
      const result = leaveapp.map((lapp: any) => {
        const approveHistory = lapp.approveHistory;
        const newApproveHistory = approveHistory.sort((a: any, b: any) => {
          return a.stage_no - b.stage_no;
        });
        lapp.approveHistory = newApproveHistory;
  
        const leaveDateAndPeriod=lapp.leaveDateAndPeriod;
        const newLeaveDateAndPeriod=leaveDateAndPeriod.sort((a: any, b: any) => {
          return new Date(a.d).getTime() - new Date(b.d).getTime();
        });
        lapp.leaveDateAndPeriod=newLeaveDateAndPeriod
        return lapp;
      });      

      return result;
    }

  // 以開始日期及請假天數排序
  static reSortLeaveAppByStartDateAndDays = (leaveapp: any[]) => {    
    const result = this.reSortLeaveApp(leaveapp);   
    return result.sort((a, b) => {
      // Get the first date in each item
      const firstDateA = new Date(a.leaveDateAndPeriod[0].d);
      const firstDateB = new Date(b.leaveDateAndPeriod[0].d);
  
      // If the first dates are equal, sort by the days in each item
      if (firstDateA.getTime() === firstDateB.getTime()) {
        const daysInItemA = a.leaveDateAndPeriod.length;
        const daysInItemB = b.leaveDateAndPeriod.length;
        return daysInItemA - daysInItemB;
      }
  
      // Otherwise, sort by the first dates
      return firstDateA.getTime() - firstDateB.getTime();
    });
  }

  /** 輸入月份回傳該月份第1天及最後1天 */
  static getFirstAndLastDateOfMonth = (year: number, month: number) => {
    const firstDate = dayjs(`${year}-${month}-01`).format("YYYY/MM/DD");
    const lastDate = dayjs(`${year}-${month}-01`).endOf("month").format("YYYY/MM/DD");
    return { firstDate, lastDate };
  }

  /** 依據傳入參數產生日期區間 */
  // mode: 0: 向前推導, 1: 向後推導, 2: 以指定日期為中心前後推導
  // interval: 推導天數
  static generateDateRange = (anchorDate: string, interval:number, mode:number) => {
    const anchorDateObj = dayjs(anchorDate);
    let startDate :string;
    let endDate :string ;
    if(mode === 0) 
    {
      startDate = anchorDateObj.subtract(interval, 'day').format('YYYY/MM/DD');
      endDate = anchorDate;
    }
    else if(mode === 1) 
    {
      startDate = anchorDate;
      endDate = anchorDateObj.add(interval, 'day').format('YYYY/MM/DD');
    }
    else  
    {
      startDate = anchorDateObj.subtract(interval, 'day').format('YYYY/MM/DD');
      endDate = anchorDateObj.add(interval, 'day').format('YYYY/MM/DD');
    }    
    return {startDate,endDate};
  }

  /** 外開新頁 */
  static handleLinkWithBlank =(innerLink:string)=>{
    window.open(innerLink,'_blank');
  }

  static formatDate = (isoString: string) => {
    const date = new Date(isoString);
    return `${date.getFullYear()}/${String(date.getMonth() + 1).padStart(2, '0')}/${String(date.getDate()).padStart(2, '0')}`;
  };
  

}