import { Card, Button, Icon, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Tag, Divider, Textarea, useDisclosure, Link } from "@chakra-ui/react";

import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { Util } from "../dal/util";
import config from '../config';
import { LeaveApplicationHelper } from "../dal/leave_application_helper";
import { NotifyBoxModal } from "./Modals/NotifyBoxModal";
import ImagePreview from "./ImagePreview";
import { useTranslation } from "react-i18next";

interface StuLeaveDetailProps {
  selectedLeaveApp: any;
  setShowDetailPanel: (data: any) => void;
  setReload: ((data: any) => void);
  prevLeaveApps: any[];
  disButtons: boolean;
}

export function StuLeaveAppDetail({
  selectedLeaveApp, setShowDetailPanel, setReload, prevLeaveApps, disButtons
}: StuLeaveDetailProps) {
  const [attachFileUrl, setAttachFileUrl] = useState<string | null>(null);
  const [signInUrl, setSignInUrl] = useState<string | null>(null);

  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [isPreviewOpen, setIsPreviewOpen] = useState<boolean>(false);

  const { isOpen: isWithdrawOpen, onOpen: onWithdrawOpen, onClose: onWithdrawClose } = useDisclosure();
  const { isOpen: isNotifyOpen, onOpen: onNotifyOpen, onClose: onNotifyClose } = useDisclosure();
  const { isOpen: isSyncNotifyOpen, onOpen: onSyncNotifyOpen, onClose: onSyncNotifyClose } = useDisclosure();
  const { isOpen: isMarkNotifyOpen, onOpen: onMarkNotifyOpen, onClose: onMarkNotifyClose } = useDisclosure();
  const [comment, setComment] = useState<string>();
  const [statusCSS, setStatusCSS] = useState<string>("");
  //const statusObj=Util.calculateProgressText(selectedLeaveApp?.status);
  //const statusCSS="w-[58px] justify-center me-2 !text-white whitespace-nowrap "+Util.calculateProgressText(selectedLeaveApp?.status).progressTextCSS;
  const { t, i18n } = useTranslation("translation");

  const reSortLeaveArray = () => {
    let leaveArray = selectedLeaveApp.leaveDateAndPeriod || [];
    leaveArray.sort((a: any, b: any) => {
      return new Date(a.d).getTime() - new Date(b.d).getTime();
    });
    return leaveArray;
  }

  const weekDays = ['日', '一', '二', '三', '四', '五', '六'];
  const weekDaysEn = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  const handleImageClick = (src: string) => {
    setSelectedImage(src);
    setIsPreviewOpen(true)
  };
  const hideImagePreview = () => {
    setSelectedImage(null);
  };
  const approveSelectedApp = async () => {
    const ids = [selectedLeaveApp?.id];
    await LeaveApplicationHelper.approve(ids);
    onNotifyOpen();
    setReload(true);
  }
  const confirmReject = async () => {
    const ids = [selectedLeaveApp?.id];
    await LeaveApplicationHelper.reject({ ids, comment });
    setReload(true);
  };

  const handleClosePreview = () => {
    setIsPreviewOpen(false);
  };
  const handleRetrySync = async () => {
    await LeaveApplicationHelper.sync(selectedLeaveApp?.id);
    onSyncNotifyOpen();
    setReload(true);
  };
  const handleMarkSyncSuccess = async () => {
    await LeaveApplicationHelper.markAsSyncSuccess(selectedLeaveApp?.id);
    onMarkNotifyOpen();
    setReload(true);
  };

  useEffect(() => {
    if (selectedLeaveApp?.attachment_info) {
      setAttachFileUrl(config.storageURL + '/' + selectedLeaveApp?.attachment_info.fileid);
    }
    if (selectedLeaveApp?.parent_sign_info) {
      setSignInUrl(config.storageURL + '/' + selectedLeaveApp?.parent_sign_info);
    }
    setStatusCSS("justify-center !text-white whitespace-nowrap flex-shrink-0 " + Util.calculateProgressText(selectedLeaveApp).progressTextCSS);
  }, [selectedLeaveApp])



  useEffect(() => {
    if (selectedLeaveApp?.attachment_info) {
      setAttachFileUrl(config.storageURL + '/' + selectedLeaveApp?.attachment_info.fileid);
    }
    if (selectedLeaveApp?.parent_sign_info) {
      setSignInUrl(config.storageURL + '/' + selectedLeaveApp?.parent_sign_info);
    }
    setStatusCSS("justify-center !text-white whitespace-nowrap flex-shrink-0 " + Util.calculateProgressText(selectedLeaveApp).progressTextCSS);
  }, [])
  return (
    <>
      <div className="bg-slate-100 w-full md:w-[45rem] relative ">

        <Card id="main" className='h-teach-r !rounded-2xl mt-8 mx-4 p-4 overflow-auto'>
          <Button colorScheme='blue' variant='ghost' size='md' className="!flex md:!hidden w-fit !ps-1 mb-2" onClick={() => setShowDetailPanel(false)}
            leftIcon={
              <Icon w={6} h={6} color='blue.500' viewBox='0 0 24 24'>
                <g fill="none" fillRule="evenodd">
                  <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
                  <path fill="currentColor" d="M8.293 12.707a1 1 0 0 1 0-1.414l5.657-5.657a1 1 0 1 1 1.414 1.414L10.414 12l4.95 4.95a1 1 0 0 1-1.414 1.414z" />
                </g>
              </Icon>}>
            <span>{t("app.back_to_approval")}</span>
          </Button>
          {
            (selectedLeaveApp) ?
              <>
                <div className='text-left'>
                  <div className="flex items-center gap-2">

                    <Tag size='md' h={8} className={statusCSS}> {i18n.language === "en" ? Util.convertStatusToTextEn(selectedLeaveApp?.status, selectedLeaveApp?.is_sync_success) : Util.convertStatusToText(selectedLeaveApp?.status, selectedLeaveApp?.is_sync_success)}</Tag>
                    {/* <Tag size='md' className="w-[58px] justify-center me-2 !text-white whitespace-nowrap bg-return">退回</Tag> */}
                    {/* <Tag size='md' className="w-[58px] justify-center me-2 !text-white whitespace-nowrap bg-ed">已完成</Tag> */}
                    <div className="text-sm">
                      <div className="flex">
                        <div className="flex-shrink-0">{t("app.pending_time")}：</div>
                        <div style={{ wordBreak: "keep-all" }}>{dayjs(selectedLeaveApp?.apply_time).format('YYYY/MM/DD HH:mm')} {i18n.language === "en" ? `By ${selectedLeaveApp?.applicant_role}` : `由${Util.convertRoleToText(selectedLeaveApp?.applicant_role)}提出`}</div>
                      </div>
                      <div>{t("app.serial_no")}：{selectedLeaveApp?.application_no}</div>
                    </div>
                  </div>
                  <div className="flex items-baseline gap-x-2">
                    <div className="text-xl">{selectedLeaveApp?.leaveStudents[0]?.studentNumber}</div>
                    <div className="text-xl font-semibold">{selectedLeaveApp?.leaveStudents[0]?.className} {selectedLeaveApp?.leaveStudents[0]?.name}</div>
                  </div>
                  {((Util.convertStatusToText(selectedLeaveApp?.status, selectedLeaveApp?.is_sync_success)) === '申請中') &&
                    <div className="text-xl font-semibold  mt-3">{t("app.to_be_approved")}：<span className=''>{i18n.language === "en" ? Util.convertRoleToTextEn(selectedLeaveApp?.current_stage_name) : selectedLeaveApp?.current_stage_name}</span></div>}
                  <Divider key={1} my={2} />

                  <div className="grid grid-cols-1 lg:grid-cols-3 grid-self gap-3 ">
                    <div>
                      <div className='text-sm font-semibold my-1'>{t("app.leave_dates")}</div>
                      {selectedLeaveApp.leaveDateAndPeriod.map((dp: any) => (
                        <div key={dp.d}>
                          <div className='text-sm font-semibold mt-2 mb-1'>{dayjs(dp.d).format('YYYY/MM/DD')}({i18n.language === "en" ? weekDaysEn[dayjs(dp.d).day()] : weekDays[dayjs(dp.d).day()]})</div>
                          <div>{dp.periods.join('、')}</div>
                        </div>
                      ))}
                      {
                        (prevLeaveApps.length > 0) &&
                        <div>
                          <div className='text-sm font-semibold my-1'>{t("app.seven_day_leave_records")}</div>
                          {
                            prevLeaveApps.map((lapp: any) => (
                              (lapp.id !== selectedLeaveApp.id) &&
                              <div key={lapp.id} className="flex items-center space-x-2">
                                <Tag
                                  size="sm"
                                  className={`${Util.calculateProgressText(lapp).progressTextCSS} !text-white justify-center whitespace-nowrap`}
                                >
                                  {i18n.language === "en" ? Util.calculateProgressText(lapp).progressTextEn : Util.calculateProgressText(lapp).progressText}
                                </Tag>
                                <div className='text-sm font-semibold mt-2 mb-1 flex-1'>
                                  {dayjs(lapp.leaveDateAndPeriod[0].d).format('YYYY/MM/DD')}
                                  {lapp.leaveDateAndPeriod.length > 1 && (
                                    <>
                                      ~{dayjs(lapp.leaveDateAndPeriod[lapp.leaveDateAndPeriod.length - 1].d).format('MM/DD')}
                                    </>
                                  )} {lapp.absence_type} {lapp.leaveDateAndPeriod.reduce((acc: number, current: any) => acc + current.periods.length, 0)} {t("app.session")}
                                </div>
                              </div>
                            ))
                          }
                        </div>

                      }


                    </div>
                    <Divider orientation='vertical' className='hidden lg:block' />
                    <Divider className='block lg:hidden' />
                    <div>
                      <div className=''>
                        <div className='text-sm font-semibold my-1'>{t("app.leave_category")}</div>
                        <div>{selectedLeaveApp?.absence_type}</div>
                      </div>
                      <Divider key={2} my={2} />
                      <div>
                        <div className='text-sm font-semibold my-1'>{t("app.leave_reason")}</div>
                        <div>{selectedLeaveApp?.reason}</div>
                      </div>
                    </div>
                  </div>
                  <Divider key={3} my={2} />
                  <div className="grid grid-cols-1 lg:grid-cols-3 grid-self gap-3 ">
                    <div>
                      {(selectedLeaveApp?.attachment_info) ?
                        (selectedLeaveApp?.attachment_info.filename.slice(-3) === 'pdf') ?
                          <>
                            <div className='text-sm font-semibold my-1'>{t("app.attachment")}</div>
                            {selectedLeaveApp?.attachment_info.filename}
                            <Link href={config.storageURL + '/' + selectedLeaveApp.attachment_info.fileid} isExternal>
                              <Icon w={8} h={8} ml={2} mb={2} viewBox='0 0 64 64'>
                                <g><path d="m26.198 30.943 3.596 1.683a1 1 0 0 0 .848 0l3.595-1.683a6.943 6.943 0 0 0 4.287-6.144v-7.616a1 1 0 0 0-.995-1 11.262 11.262 0 0 1-5.985-1.712c-.514-.314-1.248-.979-1.87-.506a11.564 11.564 0 0 1-6.768 2.218 1 1 0 0 0-.995 1v7.616a6.943 6.943 0 0 0 4.287 6.144zm-2.287-12.802a13.127 13.127 0 0 0 6.307-2.146l.241.156a13.14 13.14 0 0 0 6.065 1.99V24.8a4.975 4.975 0 0 1-3.134 4.333l-3.172 1.485-3.172-1.485a4.975 4.975 0 0 1-3.135-4.333z" fill="#00b5d8" opacity="1" data-original="#000000"></path><path d="M26.757 26.668a1.007 1.007 0 0 0 1.633.374l6.342-6.118a1 1 0 0 0-1.388-1.44l-5.24 5.055-.767-2.08a1 1 0 0 0-1.877.692z" fill="#00b5d8" opacity="1" data-original="#000000"></path><path d="M53.265 7.264H18.174a7.047 7.047 0 0 0-7.04 7.04v32.458H5a1 1 0 0 0-1 1 8.984 8.984 0 0 0 8.974 8.974h28.352a7.21 7.21 0 0 0 7.202-7.202v-30.71H59a1 1 0 0 0 1-1V14a6.743 6.743 0 0 0-6.735-6.736zM12.974 54.736a6.985 6.985 0 0 1-6.903-5.974h28.053a7.475 7.475 0 0 0 2.227 5.974zm33.554-5.202c-.216 6.867-10.183 6.88-10.404 0v-1.772a1 1 0 0 0-1-1h-21.99V14.303a5.045 5.045 0 0 1 5.04-5.039H48.48A6.713 6.713 0 0 0 46.528 14v35.534zM58 16.824h-9.472V14A4.736 4.736 0 1 1 58 14z" fill="#00b5d8" opacity="1" data-original="#000000"></path><path d="M40.534 36.248H19.901a1 1 0 0 0 0 2h20.633a1 1 0 0 0 0-2zM40.534 40.554H19.901a1 1 0 0 0 0 2h20.633a1 1 0 0 0 0-2z" fill="#00b5d8" opacity="1" data-original="#000000"></path></g>
                              </Icon>
                            </Link>
                          </>
                          :
                          <>
                            <div className='text-sm font-semibold my-1'>{t("app.attachment")}</div>
                            {selectedLeaveApp?.attachment_info.filename}
                            <div className='w-20'>
                              <img src={config.storageURL + '/' + selectedLeaveApp.attachment_info.fileid} alt="Signature" className='mx-auto imgs'
                                onClick={() => handleImageClick(config.storageURL + '/' + selectedLeaveApp.attachment_info.fileid)} />
                            </div>
                            {/*  {selectedImage && (
                      <div className="block imgPreview" onClick={hideImagePreview}>
                        <img src={selectedImage} alt="Preview" />
                      </div>
                  )}     */}
                          </>
                        :
                        <div className='whitespace-nowrap'>{t("app.no_attachment")}</div>
                      }
                    </div>
                    <Divider className='block lg:hidden' />
                    <Divider orientation='vertical' className='hidden lg:block' />
                    <div>
                      {(selectedLeaveApp?.parent_sign_info) ?
                        <>
                          <div className='text-sm font-semibold my-1'>{t("app.parent_sign")}</div>
                          <div className={`w-20 ${i18n.language === "en" ? "signbgEn" : "signbg"}`}>
                            <img src={config.storageURL + '/' + selectedLeaveApp.parent_sign_info.fileid} alt="Signature" className='mx-auto imgs'
                              onClick={() => handleImageClick(config.storageURL + '/' + selectedLeaveApp.parent_sign_info.fileid)} />
                          </div>
                        </> :
                        (selectedLeaveApp?.need_parent_signature) ?
                          <div className='whitespace-nowrap'>{t("app.parent_not_signed")}</div>
                          :
                          <div className='whitespace-nowrap'>{t("app.parent_signature_not_required")}</div>
                      }
                      {/*  {selectedImage && (
                    <div className="block imgPreview" onClick={hideImagePreview}>
                      <img src={selectedImage} alt="Preview" className='signbg' />
                    </div>
                )}*/}
                    </div>
                  </div>
                  <Divider key={4} my={2} />
                  <div className=' mb-6'>
                    <div className='text-sm font-semibold my-1'>{t("app.application_reply")}</div>
                    {selectedLeaveApp?.approveHistory.map((aph: any) => (
                      (aph.status === 'rejected') ?
                        <>
                          <div>{i18n.language === "en" ? Util.convertRoleToTextEn(aph.stage_name) : aph.stage_name}：<span className="text-xl text-red-500 font-semibold">{i18n.language === "en" ? Util.convertApproveToTextEn(aph.status) : Util.convertApproveToText(aph.status)}</span><span> {aph.comment}</span></div>
                          <div className="text-sm mb-2">{dayjs(aph.approve_time).format('YYYY/MM/DD HH:mm:ss')}</div>
                        </>
                        :
                        <>
                          <div>{i18n.language === "en" ? Util.convertRoleToTextEn(aph.stage_name) : aph.stage_name}：{i18n.language === "en" ? Util.convertApproveToTextEn(aph.status) : Util.convertApproveToText(aph.status)}</div>
                          {(aph.status === 'approved') && <div className="text-sm mb-2">{dayjs(aph.approve_time).format('YYYY/MM/DD HH:mm:ss')}</div>}
                        </>
                    ))}
                  </div>
                </div>
                {!disButtons &&
                  <div className="flex justify-center lg:justify-end gap-4 mt-auto">
                    <Button colorScheme='orange' className='!min-w-[100px]' onClick={onWithdrawOpen} isDisabled={!(selectedLeaveApp)} >{t("app.decline")}</Button>
                    <Button colorScheme='twitter' className='!min-w-[100px]' onClick={() => approveSelectedApp()} isDisabled={!(selectedLeaveApp)}>{t("app.approve")}</Button>
                  </div>
                }
                {selectedLeaveApp?.status === "finished" && !selectedLeaveApp?.is_sync_success &&
                  <div className="flex justify-center lg:justify-end gap-4 mt-auto">
                    <Button colorScheme='red' className='!min-w-[100px]' onClick={() => handleRetrySync()} isDisabled={!(selectedLeaveApp)} >{t("app.retry_sync")}</Button>
                    <Button colorScheme='twitter' className='!min-w-[100px]' onClick={() => handleMarkSyncSuccess()} isDisabled={!(selectedLeaveApp)}>{t("app.mark_as_synced")}</Button>
                  </div>
                }
              </>
              :
              <div className='flex flex-col justify-center items-center h-[800px]'>
                <img src="/assets/img/nodata1.png" alt="" className='flex w-32 mx-auto mb-4' />
              </div>
          }

        </Card>
        {selectedImage && isPreviewOpen &&
          <ImagePreview src={selectedImage} onClose={handleClosePreview} />
        }

        {/* <div {...disclosureProps} className="block sm:hidden h-screen w-full absolute top-0 bg-[#f1f5f9]"></div> */}
      </div>

      <Modal
        closeOnOverlayClick={false}
        isOpen={isWithdrawOpen}
        onClose={onWithdrawClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader className='!font-normal'>{t("app.enter_decline_reason")}</ModalHeader>
          <ModalCloseButton />

          <ModalBody pb={6}>
            <Textarea size='lg' className='!h-44 !border-gray-300' value={comment} onChange={(e) => setComment(e.target.value)} />
          </ModalBody>

          <ModalFooter className='gap-4'>
            <Button colorScheme='orange' variant='outline' className="mr-auto w-full" onClick={onWithdrawClose}>{t("app.backspace")}</Button>
            <Button colorScheme='orange' className="w-full" onClick={() => { confirmReject(); onWithdrawClose(); }}>{t("app.confirm")}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <NotifyBoxModal isModalOpen={isNotifyOpen} onModalClose={onNotifyClose} title={`${t("app.batch_approve")}`} message={`${t("app.approval_success")}`} />
      <NotifyBoxModal isModalOpen={isSyncNotifyOpen} onModalClose={onSyncNotifyClose} title={`${t("app.sync_success")}`} message={`${t("app.sync_success")}`} />
      <NotifyBoxModal isModalOpen={isMarkNotifyOpen} onModalClose={onMarkNotifyClose} title={`${t("app.mark_as_synced")}`} message={`${t("app.mark_success")}`} />

    </>
  );
}
