import { Button, Divider, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay,Tag,Text} from "@chakra-ui/react"
import dayjs from "dayjs";
import { Util } from "../../dal/util";
import config  from './../../config';
import { useEffect, useState } from "react";
interface props {
    selectedLeaveApp:any;
    selectedLeaveStu:any;
    isModalOpen:boolean;
    onModalClose:()=>void;
}

export const LeaveAppModal=({selectedLeaveApp,selectedLeaveStu,isModalOpen,onModalClose}:props)=>{
    const [selectedImage, setSelectedImage] = useState<string | null>(null);
    const [statusCSS, setStatusCSS] = useState<string>("");
    const [attachFileUrl, setAttachFileUrl] = useState<string| null>(null);
    const [signInUrl, setSignInUrl] = useState<string| null>(null); 

    const weekDays=['日','一','二','三','四','五','六'];

    const handleImageClick = (src: string) => {
        setSelectedImage(src);
      };  
      const hideImagePreview = () => {
        setSelectedImage(null);
      };
      useEffect(() => {
        if (selectedLeaveApp?.attachment_info) {
          setAttachFileUrl(config.storageURL+'/' + selectedLeaveApp?.attachment_info.fileid);
        }
        if (selectedLeaveApp?.parent_sign_info) {
          setSignInUrl(config.storageURL+'/' + selectedLeaveApp?.parent_sign_info);
        }
        setStatusCSS("justify-center me-2 !text-white whitespace-nowrap "+Util.calculateProgressText(selectedLeaveApp).progressTextCSS);    
      },[selectedLeaveApp])
    
       
      useEffect(() => {
        if (selectedLeaveApp?.attachment_info) {
          setAttachFileUrl(config.storageURL+'/' + selectedLeaveApp?.attachment_info.fileid);
        }
        if (selectedLeaveApp?.parent_sign_info) {
          setSignInUrl(config.storageURL+'/' + selectedLeaveApp?.parent_sign_info);
        }
        setStatusCSS("justify-center me-2 !text-white whitespace-nowrap "+Util.calculateProgressText(selectedLeaveApp).progressTextCSS);    

      },[])
    return(
        <Modal
            closeOnOverlayClick={false}
            isOpen={isModalOpen}
            onClose={onModalClose}>
            <ModalOverlay />
            <ModalContent maxW={{ base: "100%", sm: "400px", md: "600px", lg: "800px" }}>
                <ModalHeader className='!font-normal'></ModalHeader>
                <ModalCloseButton />

                <ModalBody pb={6}>
                <div className='text-left'>
                    <div className='flex items-baseline gap-x-2'>
                        <div className='text-xl'>{selectedLeaveStu?.studentNumber}</div>
                        <div className='text-xl font-semibold'>{selectedLeaveStu?.className} {selectedLeaveStu?.name}</div>
                    </div>
                    <div className="flex items-center">
                    <Tag size='md' h={8} className={statusCSS}> {Util.convertStatusToText(selectedLeaveApp?.status, selectedLeaveApp?.is_sync_success)}</Tag>
                    {/* <Tag size='md' className="w-[58px] justify-center me-2 !text-white bg-return">退回</Tag> */}
                    {/* <Tag size='md' className="w-[58px] justify-center me-2 !text-white bg-ed">已完成</Tag> */}
                    <div className="text-sm">
                        <div>申請時間：{ dayjs(selectedLeaveApp?.apply_time).format('YYYY/MM/DD HH:mm')} 由{ Util.convertRoleToText(selectedLeaveApp?.applicant_role)}提出</div>
                        <div>請假單號：{selectedLeaveApp?.application_no}</div>
                    </div>
                    </div>
                    <div className="text-xl font-semibold  mt-3">待簽核：<span className=''>{selectedLeaveApp?.current_stage_name}</span></div>
                    <Divider my={2} />

                    <div className="grid grid-cols-1 lg:grid-cols-3 grid-self gap-3 ">
                        <div>
                            <div className='text-sm font-semibold my-1' style={{width: '200px'}}>請假日期</div>
                            { selectedLeaveApp?.leaveDateAndPeriod.map( (dp: any) => (
                            <div key={dp.d}>
                                <div className='text-sm font-semibold mt-2 mb-1'>{ dayjs(dp.d).format('YYYY/MM/DD')}({weekDays[dayjs(dp.d).day()]})</div>
                                <div>{dp.periods.join('、')}</div>
                            </div>
                            ))}
                            
                        </div>
                        <Divider orientation='vertical' className='hidden lg:block' />
                        <Divider className='block lg:hidden' />
                        <div>
                            <div className=''>
                            <div className='text-sm font-semibold my-1'>假別</div>
                            <div>{selectedLeaveApp?.absence_type}</div>
                            </div>
                            <Divider my={2} />
                            <div>
                            <div className='text-sm font-semibold my-1'>事由</div>
                            <div>{selectedLeaveApp?.reason}</div>
                            </div>
                        </div>
                    </div>
                    <Divider my={2} />
                    <div className="grid grid-cols-1 lg:grid-cols-3 grid-self gap-3 ">
                    <div>
                        {(selectedLeaveApp?.attachment_info) ?
                        <>
                        <div className='text-sm font-semibold my-1'>附件</div>
                        <div className='w-20'>
                        <img src={config.storageURL+'/'+selectedLeaveApp.attachment_info.fileid} alt="Attachment" className='mx-auto imgs'
                        onClick={() => handleImageClick(config.storageURL+'/'+selectedLeaveApp.attachment_info.fileid)} />
                        </div>
                        {selectedImage && (
                            <div className="block imgPreview" onClick={hideImagePreview}>
                            <img src={selectedImage} alt="Signature" className='mx-auto imgs'/>  
                            </div>
                        )}                  
                        </>
                        :
                        <div className='whitespace-nowrap'>無附件</div>
                        }
                    </div>
                    <Divider className='block lg:hidden' />
                    <Divider orientation='vertical' className='hidden lg:block' />
                    <div>
                        {(selectedLeaveApp?.parent_sign_info) ?
                        <>
                        <div className='text-sm font-semibold my-1'>家長簽名</div>
                        <div className='w-20 signbg'>
                            <img src={config.storageURL+'/'+selectedLeaveApp.parent_sign_info.fileid} alt="Signature" className='mx-auto imgs'  onClick={() => handleImageClick(config.storageURL+'/'+selectedLeaveApp.parent_sign_info.fileid)} /> 
                        </div>
                        </>:
                        (selectedLeaveApp?.need_parent_signature) ?
                        <div className='whitespace-nowrap'>家長尚未簽名</div>
                        :
                        <div className='whitespace-nowrap'>不需家長簽名</div>
                        }
                        {selectedImage && (
                        <div className="block imgPreview" onClick={hideImagePreview}>
                        <img src={selectedImage} alt="Signature" className='mx-auto imgs signbg'/> 
                        </div>
                        )}
                    </div>
                    </div>
                    <Divider my={2} />
                    <div className=' mb-6'>
                        <div className='text-sm font-semibold my-1'>審查回覆</div>
                        { selectedLeaveApp?.approveHistory.map( (aph: any) => (
                        (aph.status==='rejected')? 
                        <>
                            <div>{aph.stage_name}：<span className="text-xl text-red-500 font-semibold">{ Util.convertApproveToText(aph.status)}</span><span> {aph.comment}</span></div>                  
                            <div className="text-sm mb-2">{dayjs(aph.approve_time).format('YYYY/MM/DD HH:mm:ss')}</div>
                        </>
                        :
                        <>
                            <div>{aph.stage_name}：{ Util.convertApproveToText(aph.status)}</div>
                            {(aph.status==='approved') && <div className="text-sm mb-2">{dayjs(aph.approve_time).format('YYYY/MM/DD HH:mm:ss')}</div>}
                        </>
                        ))}
                    </div>
                    </div>
                </ModalBody>

                <ModalFooter className='gap-4'>
                    <Button colorScheme='orange' variant='outline' className="mr-auto w-full" onClick={onModalClose}>返回</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}